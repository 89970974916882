import { useState, useEffect } from 'react';
import styled, { css } from 'styled-components';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { AppLink } from '@components/Link';
import { EmptyCartModal } from './modals/EmptyCartModal';
import { FlowType, TypeModeType } from '@type/EditionTypes';

interface ChannelTabsProps {
    fastLane: string;
    cartQuantity: number | undefined;
    hasBanner: boolean;
    flow: FlowType;
    typeMode: TypeModeType | null;
    previewToken: string | undefined;
    channelUrl: string;
    iframe: boolean;
}

export const ChannelTabs: React.FC<ChannelTabsProps> = (props) => {
    const { fastLane, cartQuantity, hasBanner, flow, typeMode, previewToken, channelUrl, iframe } = props;
    const location = useLocation();
    const page = location.pathname.split('/')[4];
    const { t } = useTranslation();

    const [showEmptyCartModal, setShowEmptyCartModal] = useState<boolean>(false);
    const [fastLaneUrl, setFastLaneUrl] = useState(fastLane);

    useEffect(() => {
        const storeUrl = previewToken ? `${channelUrl}&preview_token=${previewToken}` : channelUrl;
        setFastLaneUrl(`${fastLane}?s=${encodeURIComponent(storeUrl)}`);
    }, [channelUrl, previewToken]);

    const toFastLaneClickHandler = (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
        if (!cartQuantity) {
            e.preventDefault();
            setShowEmptyCartModal(true);
        }
    };

    const closeModal = () => {
        setShowEmptyCartModal(false);
    };

    // If typeMode is split, aka upselling, the tabs should be [[ Tickets | Extras | Cart ]]
    // otherwise, the tabs should be [[ Shop | Cart | Checkout ]]

    return (
        <>
            <StyledNav hasBanner={hasBanner}>
                <StyledNavList $splitMode={typeMode === 'split'}>
                    <AppLink
                        to={flow === 'reservation' ? '/shows' : '/'}
                        className={({ isActive }) => {
                            if (page == 'support' || page == 'info') return '';
                            return isActive || page === 'shows' || page === undefined
                                ? 'active'
                                : page === 'cart' || page === 'extras'
                                ? 'finished'
                                : '';
                        }}
                    >
                        <li>{typeMode === 'split' ? t('channel_tabs.tickets') : t('channel_tabs.shop')}</li>
                    </AppLink>
                    {typeMode === 'split' && (
                        <AppLink
                            to="/extras"
                            className={({ isActive }) => {
                                return isActive ? 'active' : page === 'cart' ? 'finished' : '';
                            }}
                        >
                            <li>{t('channel_tabs.extras')}</li>
                        </AppLink>
                    )}
                    <AppLink to="/cart" className={({ isActive }) => (isActive ? 'active' : '')}>
                        <li>
                            {t('channel_tabs.cart')}
                            {!!cartQuantity && <span className="cart-quantity">{cartQuantity}</span>}
                        </li>
                    </AppLink>
                    {typeMode !== 'split' && (
                        <a
                            href={fastLaneUrl}
                            target={iframe ? '_parent' : '_self'}
                            rel="noreferrer"
                            onClick={toFastLaneClickHandler}
                        >
                            <li> {t('channel_tabs.checkout')}</li>
                        </a>
                    )}
                </StyledNavList>
            </StyledNav>
            <EmptyCartModal showModal={showEmptyCartModal} closeModal={closeModal} />
        </>
    );
};

const StyledNav = styled.nav<{ hasBanner: boolean }>`
    z-index: 0;

    ${(props) =>
        !props.hasBanner &&
        !props.theme.iframe &&
        css`
            border-top-left-radius: 1rem;
            overflow: hidden;

            @media (max-width: 57em) {
                border-top-left-radius: 0;
                overflow: unset;
            }
        `}
`;

const StyledNavList = styled.ul<{ $splitMode: boolean }>`
    display: grid;
    grid-template-columns: ${(props) => (props.$splitMode ? 'auto auto auto' : 'auto auto auto')};
    gap: 0;
    overflow: hidden;
    height: 3rem;

    a {
        padding-left: 1rem;
        text-decoration: none;
        position: relative;
        display: block;
        font-weight: 600;
        font-size: 1rem;
        color: var(--theme-font-color-subtle);
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: var(--theme-gray-75);
        transition: background-color 200ms ease;

        li span.cart-quantity {
            display: inline-flex;
            justify-content: center;
            align-items: center;
            font-size: 0.875rem;
            font-weight: 700;
            padding: 2px 7px 2px;
            margin-left: 0.5rem;
            background-color: var(--theme-color-primary);
            border: 1px solid var(--theme-color-primary);
            border-radius: 0.25rem;
            color: var(--theme-canvas);
        }

        &.active {
            font-weight: 700;
        }

        &.active,
        &.finished {
            background-color: var(--theme-color-primary);
            color: var(--theme-canvas);

            li span {
                background-color: var(--theme-canvas);
                border-color: var(--theme-canvas);
                color: var(--theme-color-primary);
            }
        }

        &:first-child {
            padding-left: 0;
        }

        &:nth-of-type(1) {
            z-index: 5;

            &:after {
                z-index: 4;
            }
        }

        &:nth-of-type(2) {
            z-index: 3;

            &:after {
                z-index: 2;
            }
        }

        &:after {
            content: '';
            z-index: -1;
            border-top: 2px solid var(--theme-lines);
            border-right: 2px solid var(--theme-lines);
            background-color: inherit;
            position: absolute;
            /* height & width are fixed width because Safari doesn't fully support aspect-ratio */
            height: 2rem;
            width: 2rem;
            transform: translateY(-50%) translateX(50%) rotate(45deg) skew(15deg, 15deg);
            border-radius: 0 0.25rem;
            top: 50%;
            right: 0;
        }

        &:hover {
            background-color: ${(props) => props.theme.helpers.shades(props.theme.colors.primary, -1)};
            color: var(--theme-canvas);

            span {
                background-color: var(--theme-canvas);
                border-color: var(--theme-canvas);
                color: var(--theme-color-primary);
            }
        }
    }
`;
